import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { cls } from "../../../helpers/utils";
import { SocialLink, SocialLinkPlatform } from "../../../services/championsQueueData/index.type";
import SocialLinkFallback from "./Fallback";
import { StyledChampionSocial } from "./style";

export type Props = {
  social: SocialLink
  inverted?: boolean
};

const ChampionSocial: React.FC<Props> = ({ social, inverted }: Props) => {
  const { t } = useTranslation();

  let icon: JSX.Element;

  switch (social.platform) {
    case "instagram":
      icon = (
        <StaticImage
          className="social-icon"
          src="../../../images/socials/instagram.svg"
          alt={t("socials.instagram")}
        />
      );
      break;
    case "twitch":
      icon = (
        <StaticImage
          className="social-icon"
          src="../../../images/socials/twitch.svg"
          alt={t("socials.twitch")}
        />
      );
      break;
    case "twitter":
      icon = (
        <StaticImage
          className="social-icon"
          src="../../../images/socials/twitter.svg"
          alt={t("socials.twitter")}
        />
      );
      break;
    case "youtube":
      icon = (
        <StaticImage
          className="social-icon"
          src="../../../images/socials/youtube.svg"
          alt={t("socials.youtube")}
        />
      );
      break;
  }

  return (
    <StyledChampionSocial>
      <a
        href={social.link}
        target="_blank"
        className={cls("link", social.live && "live", inverted && "inverted")}
      >
        {social.live && (
          <span className="live-label">{t("leaderboard:labels.liveNow")}</span>
        )}
        {icon}
      </a>
    </StyledChampionSocial>
  );
};

export default ChampionSocial;

const socialPlatformOrder: SocialLinkPlatform[] = [
  "twitch", "twitter", "youtube", "instagram"
];

export const renderSocials = (
  playerName: string,
  socialLinks: SocialLink[],
  inverted: boolean
) =>
  socialPlatformOrder
    .flatMap(
      (platform) => {
        let links = socialLinks.filter((social) => social.platform === platform);

        if (links.length === 0) {
          return [(
            <SocialLinkFallback
              key={platform}
              playerName={playerName}
              platform={platform}
              inverted={inverted}
            />
          )];
        } else {
          return links.map((social, i) => (
            <ChampionSocial
              key={`${social.platform}-${i}`}
              social={social}
              inverted={inverted}
            />
          ))
        }
      }
    );
