import styled from "styled-components";
import { StyleFontHeader } from "../../../Layout/theme";

export const StyledSeeMoreChampions = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;

  .see-more-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:focus {
      outline: none;
    }

    .label {
      ${StyleFontHeader}
      position: relative;
      font-size: 32px;
      letter-spacing: 0.2em;
      color: ${({theme}) => theme.color_accent};
      padding: 0 15px;
      border-bottom: 1px solid ${({theme}) => theme.color_accent};

      /* caret */
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        border-width: 8px 8px 0 8px;
        border-color: ${({theme}) => theme.color_accent} transparent transparent transparent;
      }
    }

    .see-more-logo {
      width: 60px;
      margin-top: 20px;
    }
  }
`;
