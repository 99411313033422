import React, { Fragment, useState } from "react";
import { cls } from "../../../helpers/utils";
import { StyledDropdown } from "./style";

export type Props<T> = {
  label: string
  options: DropdownOptionGroup<T>[]
  selected?: T
  getKey: (obj: T) => string | number
  onChange: (value: T) => void
  className?: string
};

export type DropdownOptionGroup<T> = DropdownOption<T> & {
  children?: DropdownOption<T>[]
};

export type DropdownOption<T> = {
  value: T
  label: string
};

const Dropdown = <T extends any>({
  label,
  options,
  selected,
  getKey,
  onChange,
  className
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const selectHandler = (option: DropdownOption<T>) => {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (option.value !== selected) {
        onChange(option.value);
      }

      setIsOpen(false);
    };
  };

  const renderOption = (option: DropdownOption<T>, isChild: boolean = false) => (
    <button
      key={getKey(option.value)}
      className={cls(
        "option",
        selected === option.value && "selected",
        isChild && "child"
      )}
      onClick={selectHandler(option)}
    >
      {option.label}
    </button>
  );

  const renderOptionGroup = (option: DropdownOptionGroup<T>) => (
    <Fragment key={getKey(option.value)}>
      {renderOption(option)}
      {option.children?.map((child) => renderOption(child, true))}
    </Fragment>
  );

  return (
    <StyledDropdown
      className={cls(className, isOpen && "open")}
      tabIndex={0}
      onFocus={() => setIsOpen(true)}
      onBlur={handleBlur}
    >
      <button className="label">{label}</button>
      <span className="caret" />
      {isOpen && (
        <div className="options-container">
          <div className="scroll-container">
            <div className="options">
              {options.map(renderOptionGroup)}
            </div>
          </div>
          <span className="caret" />
        </div>
      )}
    </StyledDropdown>
  );
};


export default Dropdown;
