import React, { Fragment } from "react";
import ChampionSocial from "..";
import {
  SocialLink,
  SocialLinkPlatform
} from "../../../../services/championsQueueData/index.type";
import fallbacks from "./data.json";

type Props = {
  playerName: string
  platform: SocialLinkPlatform
  inverted?: boolean
};

const SocialLinkFallback: React.FC<Props> = ({
  playerName,
  platform,
  inverted
}: Props) => {
  const fallback = fallbacks.find(
    (fallback) => fallback.summonerName.toLowerCase() === playerName.toLowerCase()
  ) ?? fallbacks.find(
    (fallback) => fallback.summonerNameWithTeam.toLowerCase() === playerName.toLowerCase()
  );

  const social = fallback?.socialLinks?.find(
    (social) => social.platform === platform
  );

  if (!social) {
    return <Fragment />;
  }

  return <ChampionSocial social={social as SocialLink} inverted={inverted}/>;
};

export default SocialLinkFallback;
