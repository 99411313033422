import styled from "styled-components";
import { device, TextTransformUppercase } from "../../Layout/theme";

export const StyledChampionsSearch = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.color_accent};
  padding-bottom: 5px;

  input[type="text"] {
    background-color: transparent;
    letter-spacing: 0.1em;
    width: 270px;
    padding-left: 10px;
    padding-bottom: 5px;
    font-size: 14px;

    &::placeholder {
      ${TextTransformUppercase};
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  @media ${device.medium} {
    input[type="text"] {
      width: 200px;
    }
  }
`;
