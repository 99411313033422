import styled from "styled-components";
import { device, StyleFontHeader } from "../../../Layout/theme";

const columnWidths = {
  rank: "150px",
  socials: "300px"
};

const columnWidthsMedium = {
  rank: "75px",
  socials: "250px"
};

export const StyledChampionsLeaderboardTable = styled.div`
  .headers {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    margin-bottom: 15px;

    .header {
      ${StyleFontHeader};
      color: ${({ theme }) => theme.color_accent};
      background-color: ${({ theme }) => theme.color_background_tertiary};
      font-weight: bold;
      font-size: 22px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:not(:first-child) {
        margin-left: 3px;
      }

      &.name {
        flex-grow: 1;
      }

      &:not(.name) {
        flex-grow: 0;
        flex-shrink: 0;
      }

      &.rank { width: ${columnWidths.rank}; }

      &.socials { width: ${columnWidths.socials}; }

      &.sortable {
        cursor: pointer;

        &:focus {
          border: none;
          outline: none;
        }

        .caret {
          margin-left: 10px;

          &:not(.desc) {
            transform: scaleY(-1);
          }
        }
      }
    }
  }

  .player-row {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    position: relative;

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.color_background_tertiary};
    }

    &.first {
      .row-cell, .info-small {
        background-color: ${({ theme }) => theme.color_victory};
        color: ${({ theme }) => theme.color_text_dark};
      }
    }

    &.with-divider {
      margin-bottom: 32px;

      &::after {
        content: "";
        position: absolute;
        bottom: -17px;
        height: 2px;
        width: 100%;
        background-color: ${({ theme }) => theme.color_accent};
      }
    }

    .row-cell {
      font-size: 20px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }
  }

  @media ${device.medium_and_large} {
    .player-row {
      &:nth-child(even) {
        .row-cell:nth-of-type(n + 3) {
          &::before {
            content: "";
            position: absolute;
            left: -3px;
            top: 0;
            bottom: 0;
            width: 3px;
            background-color: ${({ theme }) => theme.color_background_tertiary};
          }
        }
      }

      .info-small {
        display: none;
      }

      .row-cell {
        &:nth-of-type(n + 3) {
          margin-left: 3px;
        }

        &.name {
          flex-grow: 1;
        }

        &:not(.name) {
          flex-grow: 0;
          flex-shrink: 0;
        }

        &.rank {
          ${StyleFontHeader};
          width: ${columnWidths.rank};
        }

        &.stat {
          font-weight: bold;
        }

        &.socials {
          width: ${columnWidths.socials};
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }

  @media ${device.medium} {
    .headers {
      .header {
        &.rank { width: ${columnWidthsMedium.rank}; }
        &.socials { width: ${columnWidthsMedium.socials}; }
      }
    }

    .player-row {
      .row-cell {
        &.rank { width: ${columnWidthsMedium.rank}; }
        &.socials { width: ${columnWidthsMedium.socials}; }
      }
    }
  }

  @media ${device.small} {
    .headers {
      /* Only show columns that support sorting. */
      .header {
        &.sortable { width: auto; }
        &:not(.sortable) { display: none; }
      }
    }

    .player-row {
      flex-direction: column;

      .row-cell {
        padding: ${({ theme }) => theme.gutter_size_small};

        &.rank, &.stat, &.name { display: none; }
        &.socials { padding-top: 0; }
      }

      .info-small {
        padding: ${({ theme }) => theme.gutter_size_small};

        .small-top-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-bottom: 10px;
        }

        .small-rank {
          ${StyleFontHeader};
          font-size: 24px;
          line-height: 24px;
          height: 22px;
          margin-right: 15px;
        }

        .small-name {
          font-size: 20px;
        }
      }
    }
  }
`;
