import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSeeMoreChampions } from "./style";

export type Props = {
  onClick: () => void
};

const SeeMoreChampions: React.FC<Props> = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledSeeMoreChampions>
      <button className="see-more-button" onClick={onClick}>
        <span className="label">{t("leaderboard:labels.seeMore")}</span>
        <StaticImage
          className="see-more-logo"
          src="../../../../images/emea_icon.png"
          width={60}
          alt={t("nav.logo")}
        />
      </button>
    </StyledSeeMoreChampions>
  );
};

export default SeeMoreChampions;
