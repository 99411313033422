import { StaticImage } from "gatsby-plugin-image";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cls } from "../../../../helpers/utils";
import { useGetRankWithSuffix } from "../../../../hooks/use-get-rank-with-suffix";
import { SocialLink } from "../../../../services/championsQueueData/index.type";
import { renderPlayerName } from "../../PlayerName";
import { renderSocials } from "../../Social";
import SeeMoreChampions from "../SeeMore";
import { StyledChampionsLeaderboardTable } from "./style";

export type Props<T extends BasePlayer> = {
  players: T[]
  stats: StatColumn<T>[]
  highlightAndDividers: boolean
};

export type BasePlayer = {
  name: string
  rank: number
  socialLinks: SocialLink[]
};

export type StatColumn<T> = {
  key: string // e.g. "lp"
  label: string // e.g. "LP"
  getValue: (player: T) => number // e.g. 200
  getRawValue: (player: T) => number // e.g. 200; use for sorting
  getValueFormattingOptions: Intl.NumberFormatOptions
  renderValue: (player: T) => string // e.g. "LP: 200"
  className?: string // Added to the header and value cells
  showDividers?: boolean
};

type SortDirection = "asc" | "desc";

const sortByRank = "rank";

const pageSize = 25;

const ChampionsLeaderboardTable = <T extends BasePlayer>({
  players,
  stats,
  highlightAndDividers
}: Props<T>) => {
  const { t } = useTranslation();
  const getRankWithSuffix = useGetRankWithSuffix();

  const [sortBy, setSortBy] = useState<string>("rank");
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");
  const [pagesShown, setPagesShown] = useState<number>(1);

  const playersSorted = useMemo(
    () => [...players].sort(sortPlayers(sortBy, sortDirection, stats)),
    [players, stats, sortBy, sortDirection]
  );

  const playersSortedAndPaged = useMemo(
    () => playersSorted.slice(0, pagesShown * pageSize),
    [playersSorted, pagesShown]
  );

  const renderCaret = () => (
    <StaticImage
      className={cls("caret", sortDirection === "desc" && "desc")}
      src="../../../../images/caret.svg"
      alt={t(`leaderboard:sort.${sortDirection}`)}
    />
  );

  const handleSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    }
    else {
      setSortBy(newSortBy);
      setSortDirection("asc");
    }
  };

  const shouldRenderDividerAfter = (rank: number, nextRank?: number) => {
    if (
      !highlightAndDividers
      || nextRank === undefined
      || nextRank === rank
    ) {
      return false;
    }

    const stat = stats.find((stat) => stat.key === sortBy);

    if (stat) {
      if (!stat.showDividers) {
        return false;
      }
    } else if (sortBy !== 'rank') {
      return false;
    }

    if (stat ? sortDirection === 'asc' : sortDirection === 'desc') {
      // Swap ranks for descending so that the divider is shown before instead of
      // after in the ascending case.
      let temp = rank;
      rank = nextRank;
      nextRank = temp;
    }

    if (rank < 15) {
      return rank === 1 || rank % 5 === 0;
    }

    return rank % 25 === 0;
  };

  const renderHeader = () => (
    <div className="headers">
      <button
        className="header sortable rank"
        onClick={() => handleSort(sortByRank)}
      >
        {t("leaderboard:headers.rank")}
        {sortBy === sortByRank && renderCaret()}
      </button>
      {stats.map((stat) => (
        <button
          key={stat.key}
          className={cls("header", "sortable", stat.className)}
          onClick={() => handleSort(stat.key)}
        >
          {stat.label}
          {sortBy === stat.key && renderCaret()}
        </button>
      ))}
      <div className="header name">{t("leaderboard:headers.name")}</div>
      <div className="header socials">{t("leaderboard:headers.socials")}</div>
    </div>
  );

  const renderInfoSmall = (player: T) => (
    <div className="info-small">
      <div className="small-top-row">
        <div className="small-rank">
          {t("leaderboard:labels.rank", { value: getRankWithSuffix(player.rank) })}
        </div>
        <div className="small-name">
          {player.name}
        </div>
      </div>
      <div>
        {stats.map((stat) => (
          <div key={stat.key}>{stat.renderValue(player)}</div>
        ))}
      </div>
    </div>
  );

  const renderRow = (player: T, i: number, arr: T[]) => (
    <div
      className={cls(
        "player-row",
        highlightAndDividers && player.rank === 1 && "first",
        shouldRenderDividerAfter(player.rank, arr[i + 1]?.rank) && "with-divider"
      )}
      key={`${player.name}-${i}`}
    >
      {renderInfoSmall(player)}
      <div className="row-cell rank">
        {player.rank.toLocaleString()}
      </div>
      {stats.map((stat) => (
        <div key={stat.key} className={cls("row-cell", "stat", stat.className)}>
          {stat.getValue(player).toLocaleString(undefined, stat.getValueFormattingOptions)}
        </div>
      ))}
      <div className="row-cell name">{renderPlayerName(player.name)}</div>
      <div className="row-cell socials">
        {renderSocials(
          player.name,
          player.socialLinks,
          highlightAndDividers && player.rank === 1
        )}
      </div>
    </div>
  );

  const canSeeMore = playersSortedAndPaged.length < playersSorted.length;

  return (
    <StyledChampionsLeaderboardTable>
      {renderHeader()}
      {playersSortedAndPaged.map(renderRow)}
      {canSeeMore && (
        <SeeMoreChampions
          onClick={() => setPagesShown((pagesShown) => pagesShown + 1)}
        />
      )}
    </StyledChampionsLeaderboardTable>
  );
};

const sortPlayers = <T extends BasePlayer>(
  sortBy: string,
  sortDirection: SortDirection,
  stats: StatColumn<T>[]
) =>
  (a: T, b: T) => {
    let valueA: number;
    let valueB: number;

    if (sortBy === sortByRank) {
      valueA = a.rank;
      valueB = b.rank;
    } else {
      const stat = stats.find((stat) => stat.key === sortBy);

      if (!stat) {
        return 0;
      }

      valueA = stat.getRawValue(a);
      valueB = stat.getRawValue(b);
    }

    return (sortDirection === "asc") ? (valueA - valueB) : (valueB - valueA);
  };

export default ChampionsLeaderboardTable;
