import styled from "styled-components";
import { StyledScrollbar, StyleFontHeader, TextTransformUppercase } from "../../Layout/theme";

export const StyledDropdown = styled.div`
  position: relative;
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.color_accent};

  &:focus {
    outline: 0;
  }

  &.open > .caret {
    display: none;
  }

  .caret {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    border-width: 8px 8px 0 8px;
    border-color: ${({theme}) => theme.color_accent} transparent transparent transparent;
  }

  .label {
    ${TextTransformUppercase};
    color: ${({ theme }) => theme.color_accent};
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    user-select: none;

    &:focus {
      outline: 0;
    }
  }

  .options-container {
    position: absolute;
    left: 0;
    min-width: 200px;
    top: 100%;
    background-color: ${({ theme }) => theme.color_background_tertiary};
    border: 1px solid ${({ theme }) => theme.color_accent};
    box-shadow: 0px 0px 15px 0px ${({ theme }) => theme.color_background_tertiary};
    z-index: 99;

    .scroll-container {
      ${StyledScrollbar}
      min-height: 150px;
      max-height: 300px;
      overflow-y: auto;
    }

    .caret {
      transform: translate(-50%, 0%) scaleY(-1);
    }

    .options {
      .option {
        ${StyleFontHeader};
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.15em;
        padding: 10px;
        display: block;
        text-align: left;

        &:focus {
          outline: none;
        }

        &.selected {
          color: ${({ theme }) => theme.color_accent};
          pointer-events: none;
        }

        &.child {
          padding-left: 40px;
        }
      }
    }
  }
`;
