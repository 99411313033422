import React, { useEffect, useState } from "react";
import { StyledChampionsSearch } from "./style";
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";

export type Props = {
  onSearch: (query: string) => void
};

const ChampionsSearch: React.FC<Props> = ({ onSearch }: Props) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");
  const [wasNonEmpty, setWasNonEmpty] = useState<boolean>(false);

  // Notify throttled when typing
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query.trim().length > 0) {
        onSearch(query);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query]);

  // Notify when the user clears the query
  useEffect(() => {
    const isNonEmpty = query.trim().length > 0;

    if (isNonEmpty) {
      setWasNonEmpty(true);
    }
    else if (wasNonEmpty) {
      // it's empty now.
      onSearch("");
      setWasNonEmpty(false);
    }

  }, [query, wasNonEmpty]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  };

  return (
    <StyledChampionsSearch>
      <StaticImage
        src="../../../images/search-icon.svg"
        alt={t("searchPlayerName")}
      />
      <input
        type="text"
        value={query}
        placeholder={t("searchPlayerName")}
        onChange={handleChange}
      />
    </StyledChampionsSearch>
  );
};

export default ChampionsSearch;
