import styled from "styled-components";
import { StyleFontHeader } from "../../Layout/theme";

export const StyledChampionSocial = styled.div`
  margin-right: 15px;

  .link {
    display: flex;
    align-items: center;

    &.live {
      padding: 5px;
      border: 1px solid ${({ theme }) => theme.color_accent};

      .live-label {
        ${StyleFontHeader};
        color: ${({ theme }) => theme.color_accent};
        margin-right: 5px;
        font-size: 12px;
        line-height: 12px;
        height: 10px;
        white-space: nowrap;
      }
    }

    &.inverted {
      &.live {
        border-color: ${({ theme }) => theme.color_text_dark};

        .live-label {
          color: ${({ theme }) => theme.color_text_dark};;
        }
      }

      .social-icon {
        filter: invert(100%);
      }
    }

    .social-icon {
      height: 18px;
    }
  }
`;
